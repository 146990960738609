<script setup>
import { onMounted, ref } from "vue"
import { useConfirm } from "primevue/useconfirm";
import { useRoute } from 'vue-router'
import { useToast } from "primevue/usetoast"
import { useActivitiesStore } from "@/store/activity"

import ActivityAccountStatusOfMember from "@/components-uhas/activities/ActivityAccountStatusOfMember"

const activityStore = useActivitiesStore()
const confirm = useConfirm()
const toast = useToast()

const activity = ref({})
const members = ref([])

const loading = ref(true)
const loadingResetting = ref(false)

const route = useRoute()

const initial = () => {
	const id = route.params.id
	activityStore.indexMembers(id).then((res) => {
		activity.value = res.data.activity
		if (activity.value.finished_at) {
			activity.value.finished_at = new Date(activity.value.finished_at).toLocaleString()
		}

		members.value = res.data.activity.members

		members.value.sort((a,b) => b.pivot.score - a.pivot.score)

    loading.value = false
  })
}

const resetDisabledConnect = (event) => {
	confirm.require({
		target: event.currentTarget,
		message: 'Are you sure you want to proceed?',
		header: 'Confirmation',
		accept: () => {
			loadingResetting.value = true

			const id = route.params.id
			activityStore.resetDisabledConnect(id).then(() => {
				initial()

				toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})
			}).catch((e) => {
				toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
			}).finally(() => {
				loadingResetting.value = false
			})
		}
	});
}

onMounted(() => {
	initial()
})

</script>

<template>
	<div class="block-section">
    <div class="block-header">
        <span v-if="activity.id" class="block-title">
            <div>Show #{{ activity.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>

				<br/>
    </div>

		<div class="block-content">
			<div class="grid">
				<div class="col-12">
					<div class="card">
						<h5>Activity</h5>

						<label class="block text-900 font-medium mb-2" for="heading">Heading</label>
						<InputText v-model="activity.heading" id="heading" type="text" class="w-full mb-3" readonly />

						<label class="block text-900 font-medium mb-2" for="finished_at">Finished At</label>
						<InputText v-model="activity.finished_at" id="finished_at" type="text" class="w-full mb-3" readonly />

						<label class="block text-900 font-medium mb-2" for="description">Description</label>
						<Textarea v-model="activity.description" id="description" rows="5" class="w-full mb-3" readonly />

						<label class="block text-900 font-medium mb-2" for="is_main_activity">Main Activity</label>
						<InputSwitch v-model="activity.is_main_activity" id="is_main_activity" class="mb-3" :disabled="true" />
						

					</div>
				</div>
			</div>
		</div>

		<div class="block-content">
			<div class="grid">
				<div class="col-12">
					<div class="card">
						<h5>
							Activities Show Members
							<span>( {{ members.length.toLocaleString('en-US') }} Users / Connected {{ members.filter(x => x.pivot.connected_at).length.toLocaleString('en-US') }} / Not Connected {{ (members.length - members.filter(x => x.pivot.connected_at).length).toLocaleString('en-US') }} / Disabled {{ members.filter(x => x.pivot.is_disabled_connect).length.toLocaleString('en-US') }} )</span>
							<span class="p-2"></span>
							<Button
								class="p-button-warning"
								label="Reset Disabled Connect"
								:loading="loadingResetting"
								@click="resetDisabledConnect($event)"
							/>
						</h5>
						<DataTable 
							:value="members"
							:loading="loading"
							responsiveLayout="scroll"
						>
							<template #empty>
								No records.
							</template>
							<template #loading>
								Loading. Please wait.
							</template>

							<Column header="Member ID">
								<template #body="{ data }">
									<RouterLink :to="{ name: 'members.show', params: { id: data.id } }">
										{{ data.phone }}
									</RouterLink>
									<br/>
									<small>Uhas: {{ data.uhas_uid }}</small>
								</template>
							</Column>

							<Column field="pivot.score" header="Score" />

							<Column header="Server">
								<template #body="{ data }">
									<div class="flex flex-wrap">
										<activity-account-status-of-member :data="data.pivot" />

										<span class="mx-2" />

										<div class="flex align-items-center">
											{{ data.pivot.server }}
										</div>
									</div>									
								</template>
							</Column>

							<Column field="pivot.login" header="Login" />
							<Column field="pivot.password" header="Password" />

							<Column field="pivot.updated_at" header="Updated At" />

							<Column header="Options">
								<template #body="{ data }">
									<router-link :to="`/activities/${activity.id}/members/${data.id}/edit`">
										<Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2 mb-2" />
									</router-link>
								</template>
							</Column>

						</DataTable>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
