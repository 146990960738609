import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useActivitiesStore = defineStore('activity', {
  actions: {
		index: ({ page = 1, option }) => {
			option = option ?? {}
			const query = [`page=${page}`]
			if (typeof option.isRegister === 'number') query.push(`isRegister=${option.isRegister}`)
			if (typeof option.isActive === 'number') query.push(`isActive=${option.isActive}`)
			if (typeof option.filter === 'string') query.push(`filter=${option.filter}`)

			return axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/activities?${query.join('&')}`)
		},
		indexMembers: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/activities/${id}/members`),
		resetDisabledConnect: (id) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/activities/${id}/reset-disabled-connect`),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/activities/${id}`),
		showMember: (id, memberId) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/activities/${id}/member/${memberId}`),
		store: (activity) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/activities`, activity),
		update: (id, activity) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/activities/${id}`, activity),
		updateMember: (id, memberId, activity) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/activities/${id}/member/${memberId}`, activity),
		updateMainActivity: (id, mainActivity) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/activities/${id}/main-activity`, mainActivity),
  }
})
